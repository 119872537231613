// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b5Q5V{padding-bottom:20px}.D8n1o{padding:20px 0}.GnTl1{display:grid;grid-gap:24px;grid-template-rows:repeat(2, 1fr);grid-template-columns:repeat(5, 1fr);width:100%}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/inspirations/Inspirations.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CAGF,OACE,cAAA,CAGF,OACE,YAAA,CACA,aAAA,CACA,iCAAA,CACA,oCAAA,CACA,UAAA","sourcesContent":[".inspirationsSection {\n  padding-bottom: 20px;\n}\n\n.inspirationsTitle {\n  padding: 20px 0;\n}\n\n.inspirationsList {\n  display: grid;\n  grid-gap: 24px;\n  grid-template-rows: repeat(2, 1fr);\n  grid-template-columns: repeat(5, 1fr);\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inspirationsSection": "b5Q5V",
	"inspirationsTitle": "D8n1o",
	"inspirationsList": "GnTl1"
};
export default ___CSS_LOADER_EXPORT___;
