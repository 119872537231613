import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'components/augmentedOccasionsResult/adsAndAssets/AdsAndAssets.module.scss'
import { ImpactScoreWithTouchpoints } from 'components/augmentedOccasionsResult/adsAndAssets/impactScoreWithTouchpoints/ImpactScoreWithTouchpoints'
import { OverallCognitiveImpactScore } from 'components/augmentedOccasionsResult/adsAndAssets/overallCognitiveImpactScore/OverallCognitiveImpactScore'
import { ResultsOverTime } from 'components/augmentedOccasionsResult/adsAndAssets/resultsOverTime/ResultsOverTime'
import { Flex } from 'components/common/flex/Flex'
import { OccasionItemDto } from 'types/dto/augmentedOccasionsDto'

interface Props {
  occasion: OccasionItemDto
}

export const AdsAndAssets = ({ occasion }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={styles.adsAndAssetsSection}>
      <WppTypography type="2xl-heading" tag="h2" className={styles.adsAndAssetsTitle}>
        {t('ads_and_assets_section_title')}
      </WppTypography>
      <Flex gap={24}>
        <OverallCognitiveImpactScore occasion={occasion} />
        <ResultsOverTime />
      </Flex>
      <ImpactScoreWithTouchpoints />
    </div>
  )
}
