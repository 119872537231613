import {
  WppTypography,
  WppDivider,
  //WppActionButton,
  //WppIconTrash
} from '@platform-ui-kit/components-library-react'
// import { useState } from 'react'
// import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import styles from 'components/augmentedOccasionsList/augmentedOccasionsListItem/AugmentedOccasionsListItem.module.scss'
import { Flex } from 'components/common/flex/Flex'
// import { WarningConfirmationModal } from 'components/warningConfirmationModal/WarningConfirmationModal'
import { AugmentedOccasionsDto } from 'types/dto/augmentedOccasionsDto'

interface Props {
  occasion: AugmentedOccasionsDto
}

export const AugmentedOccasionsListItem = ({ occasion }: Props) => {
  // const { t } = useTranslation()
  const navigate = useNavigate()

  // const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)

  // const handleConfirmDelete = () => {
  //   // TODO: implement delete (api)
  //   setIsDeleteConfirmationModalOpen(false)
  // }

  return (
    <>
      <div>
        <div className={styles.occasionItem} onClick={() => navigate(`/${occasion.id}?without-header`)}>
          <Flex justify="between" align="center">
            <WppTypography type="s-body">{occasion.name}</WppTypography>
            {/* <WppActionButton
              onClick={e => {
                e.stopPropagation()
                console.log('delete clicked')
                setIsDeleteConfirmationModalOpen(true)
              }}
              className={styles.deleteButton}
            >
              <WppIconTrash size="m" color="var(--wpp-grey-color-800)" />
            </WppActionButton> */}
          </Flex>
        </div>
        <WppDivider />
      </div>
      {/* <WarningConfirmationModal
        open={isDeleteConfirmationModalOpen}
        title={t('delete_augmented_occasion')}
        bodyText={`${t('this_will_permanently_delete')} ${occasion.name}`}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        onConfirm={handleConfirmDelete}
      /> */}
    </>
  )
}
