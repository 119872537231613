// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sDrMi{width:50%;height:464px}.dct2C{width:100%;height:100%}.arIAu{height:100%;margin-top:-24px}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/adsAndAssets/overallCognitiveImpactScore/OverallCognitiveImpactScore.module.scss"],"names":[],"mappings":"AAAA,OACE,SAAA,CACA,YAAA,CAGF,OACE,UAAA,CACA,WAAA,CAGF,OACE,WAAA,CACA,gBAAA","sourcesContent":[".chartCard {\n  width: 50%;\n  height: 464px;\n}\n\n.chartWrapper {\n  width: 100%;\n  height: 100%;\n}\n\n.spinnerWrapper {\n  height: 100%;\n  margin-top: -24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartCard": "sDrMi",
	"chartWrapper": "dct2C",
	"spinnerWrapper": "arIAu"
};
export default ___CSS_LOADER_EXPORT___;
