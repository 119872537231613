import {
  WppActionButton,
  WppCard,
  WppIconClose,
  WppModal,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import styles from 'components/augmentedOccasionsResult/creatives/creativeCard/CreativeCard.module.scss'
import { CreativesDto } from 'types/dto/creativesDto'

interface CardProps {
  creative: CreativesDto
}

export const CreativeCard = ({ creative }: CardProps) => {
  const [isOpen, setOpen] = useState(false)
  const handleCloseModal = () => setOpen(false)
  const analyse = creative.analysis[0]
  const currentCreative = analyse.creativeVisuals.find(
    (creativeVisual: { channel: { id: any }; zone: { id: any } }) =>
      creativeVisual.channel?.id === analyse.channelZoneTouchpoint?.channel?.id &&
      creativeVisual.zone?.id === analyse.channelZoneTouchpoint?.zone?.id,
  )

  const getColorStatus = (status: number | null, isBrain: boolean = false) => {
    if (!status) {
      return null
    }
    if (isBrain ? status === 1 : status <= 33) {
      return styles.danger
    } else if (isBrain ? status === 2 : status > 33 && status < 66) {
      return styles.warning
    }
    return styles.success
  }

  const getExtention = () => {
    const getExt = (name: string) => name.split('.').pop()
    const ext = getExt(creative.name) ?? getExt(currentCreative.imageUrl)
    return ext?.toUpperCase()
  }

  return (
    <>
      <WppCard className={styles.container} onClick={() => setOpen(true)}>
        <img src={currentCreative?.imageUrl} alt={currentCreative.name} />
        <div className={styles.mainContentWrapper}>
          <div className={styles.infoBlock}>
            <WppTypography type="xs-body" className={styles.extention}>
              {getExtention()}
            </WppTypography>
            <div className={styles.icons}>
              <div className={clsx(styles.icon, styles.eye, getColorStatus(currentCreative.visualSalienceValue))} />
              <div
                className={clsx(
                  styles.icon,
                  styles.brain,
                  getColorStatus(currentCreative.perceptualSalienceScore, true),
                )}
              />
            </div>
          </div>
          <WppTypography type="m-strong">{creative.name}</WppTypography>
          <WppTypography type="s-midi">{creative.description}</WppTypography>
        </div>
      </WppCard>
      <WppModal open={isOpen} className={styles.modal}>
        <div slot="header" className={styles.modalHeader}>
          <WppTypography type="2xl-heading">{creative.name}</WppTypography>
          <WppActionButton onClick={handleCloseModal}>
            <WppIconClose color="var(--button-primary-bg-color)" className={styles.close} />
          </WppActionButton>
        </div>
        <iframe slot="body" src={creative.embedUrl} className={styles.iframe} title={creative.name} />
      </WppModal>
    </>
  )
}
