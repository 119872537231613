import { WppTypography, WppButton, WppIconAdd, WppSpinner } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/augmentedOccasionsList/AugmentedOccasionsList.module.scss'
import { AugmentedOccasionsListItem } from 'components/augmentedOccasionsList/augmentedOccasionsListItem/AugmentedOccasionsListItem'
import { Flex } from 'components/common/flex/Flex'
import { CreateOccasionSideModal } from 'components/createOccasionSideModal/CreateOccasionSideModal'
import { useAugmentedOccasions } from 'hooks/useAugmentedOccasions'

export const AugmentedOccasionsList = () => {
  const { t } = useTranslation()
  const { savedOccasions, savedOccasionsIsLoading } = useAugmentedOccasions()

  const [createOccasionModalIsVisible, setCreateOccasionModalIsVisible] = useState(false)

  return (
    <>
      <Flex justify="between" gap={24} className={styles.header}>
        <WppTypography type="3xl-heading" tag="h1">
          {t('augmented_occassions')}
        </WppTypography>
        <WppButton onClick={() => setCreateOccasionModalIsVisible(true)}>
          <WppIconAdd slot="icon-start" />
          {t('create_augmented_occassion')}
        </WppButton>
      </Flex>

      {savedOccasions?.length === 0 ? (
        <>
          {!savedOccasionsIsLoading && (
            <WppTypography type="l-strong" tag="h1" className={styles.emptyListText}>
              {t('empty_saved_occasions_list')}
            </WppTypography>
          )}
        </>
      ) : (
        <>
          {savedOccasions?.map(occasion => (
            <AugmentedOccasionsListItem occasion={occasion} key={occasion.id} />
          ))}
        </>
      )}

      {savedOccasionsIsLoading && (
        <Flex justify="center" align="center" className={styles.loadingSpinnerContainer}>
          <WppSpinner size="l" />
        </Flex>
      )}

      <CreateOccasionSideModal
        isOpen={createOccasionModalIsVisible}
        onCloseModal={() => setCreateOccasionModalIsVisible(false)}
      />
    </>
  )
}
