import { WppTypography } from '@platform-ui-kit/components-library-react'
import * as echarts from 'echarts'
import { useEffect, useRef, useState, useCallback } from 'react'

import styles from 'components/augmentedOccasionsResult/adsAndAssets/impactScoreWithTouchpoints/impactBarChart/ImpactBarChart.module.scss'
import { useResizeObserver } from 'hooks/useResizeObserver'
import { ChartScoreDto } from 'types/dto/chartsReportDto'

interface Props {
  title: string
  chartData: ChartScoreDto
}

export const ImpactBarChart = ({ title, chartData }: Props) => {
  const [chartWidth, setChartWidth] = useState(600)

  const onResize = useCallback((target: HTMLDivElement) => {
    setChartWidth(target.clientWidth - 32)
  }, [])

  const barChart = useRef(null)
  const chartWrapperRef = useResizeObserver(onResize)

  useEffect(() => {
    if (!barChart.current) return

    var myChart = echarts.init(barChart.current)
    myChart.resize({
      width: chartWidth,
      height: 400,
    })

    if (chartData) {
      var option

      option = {
        legend: {
          orientation: 'horizontal',
          right: 0,
          top: 'top',
          icon: 'circle',
        },
        xAxis: {
          type: 'category',
          data: ['High', 'Medium', 'Low'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            type: 'bar',
            data: [
              {
                value: chartData.HIGH,
                name: 'High',
                itemStyle: {
                  color: '#1FBC59',
                },
              },
              {
                value: chartData.MEDIUM,
                name: 'Medium',
                itemStyle: {
                  color: '#EAB308',
                },
              },
              {
                value: chartData.LOW,
                name: 'Low',
                itemStyle: {
                  color: '#F87171',
                },
              },
            ],
          },
        ],
      }

      myChart.setOption(option)
    }
  }, [barChart, chartWidth, chartData])

  return (
    <div className={styles.chartWrapper} ref={chartWrapperRef}>
      <WppTypography type="s-strong" className={styles.title}>
        {title}
      </WppTypography>
      <div ref={barChart} className={styles.chart} />
    </div>
  )
}
