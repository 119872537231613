// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wgovq{margin:8px 0;padding:16px 8px;border-radius:var(--wpp-border-radius-s)}.Wgovq:hover{cursor:pointer;background-color:var(--wpp-grey-color-200)}.Wgovq:hover .MJzfg{visibility:visible}.MJzfg{visibility:hidden}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsList/augmentedOccasionsListItem/AugmentedOccasionsListItem.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,gBAAA,CACA,wCAAA,CAEA,aACE,cAAA,CACA,0CAAA,CAEA,oBACE,kBAAA,CAKN,OACE,iBAAA","sourcesContent":[".occasionItem {\n  margin: 8px 0;\n  padding: 16px 8px;\n  border-radius: var(--wpp-border-radius-s);\n\n  &:hover {\n    cursor: pointer;\n    background-color: var(--wpp-grey-color-200);\n\n    & .deleteButton {\n      visibility: visible;\n    }\n  }\n}\n\n.deleteButton {\n  visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"occasionItem": "Wgovq",
	"deleteButton": "MJzfg"
};
export default ___CSS_LOADER_EXPORT___;
