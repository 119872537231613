import { WppTypography, WppCard } from '@platform-ui-kit/components-library-react'
import * as echarts from 'echarts'
import { useEffect, useRef, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/augmentedOccasionsResult/adsAndAssets/resultsOverTime/ResultsOverTime.module.scss'
import { useResizeObserver } from 'hooks/useResizeObserver'

export const ResultsOverTime = () => {
  const { t } = useTranslation()

  const [chartWidth, setChartWidth] = useState(600)

  const onResize = useCallback((target: HTMLDivElement) => {
    setChartWidth(target.clientWidth - 32)
  }, [])

  const lineChart = useRef(null)
  const chartWrapperRef = useResizeObserver(onResize)

  useEffect(() => {
    if (lineChart.current) {
      var myChart = echarts.init(lineChart.current)
      var option

      option = {
        color: ['#1FBC59', '#EAB308', '#F87171'],
        legend: {
          orientation: 'horizontal',
          right: 0,
          top: 'top',
          icon: 'circle',
        },
        xAxis: {
          type: 'category',
          data: ['Q1', 'Q2', 'Q3', 'Q4'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'High',
            type: 'line',
            showSymbol: false,
            data: [220, 182, 191, 234, 290, 330, 310],
          },
          {
            name: 'Medium',
            type: 'line',
            showSymbol: false,
            data: [150, 232, 201, 154, 190, 330, 410],
          },
          {
            name: 'Low',
            type: 'line',
            showSymbol: false,
            data: [230, 190, 101, 66, 50, 230, 210],
          },
        ],
      }

      myChart.setOption(option)
      myChart.resize({
        width: chartWidth,
        height: 400,
      })
    }
  }, [lineChart, chartWidth])

  return (
    <WppCard className={styles.chartCard}>
      <div slot="header">
        <WppTypography type="m-strong">{t('ads_and_assets.results_over_time_for')} 2023</WppTypography>
      </div>
      <div className={styles.chartWrapper} ref={chartWrapperRef}>
        <div ref={lineChart} />
      </div>
    </WppCard>
  )
}
