// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sk9LD{margin-bottom:8px;padding:4px 8px;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-s);cursor:pointer}.sk9LD:hover{background-color:var(--wpp-primary-color-100)}.hAvMi{background-color:var(--wpp-primary-color-400)}.hAvMi:hover{background-color:var(--wpp-primary-color-400)}.Wrs1v{color:var(--wpp-grey-color-800)}.nstbj{color:var(--wpp-primary-color-100)}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/occasionsResult/occasionListItem/OccasionListItem.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,eAAA,CACA,0CAAA,CACA,wCAAA,CACA,cAAA,CAEA,aACE,6CAAA,CAIJ,OACE,6CAAA,CAEA,aACE,6CAAA,CAIJ,OACE,+BAAA,CAGF,OACE,kCAAA","sourcesContent":[".tabResultsItem {\n  margin-bottom: 8px;\n  padding: 4px 8px;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-s);\n  cursor: pointer;\n\n  &:hover {\n    background-color: var(--wpp-primary-color-100);\n  }\n}\n\n.tabResultsItemActive {\n  background-color: var(--wpp-primary-color-400);\n\n  &:hover {\n    background-color: var(--wpp-primary-color-400);\n  }\n}\n\n.tabResultsSubtitle {\n  color: var(--wpp-grey-color-800);\n}\n\n.selectedTextColor {\n  color: var(--wpp-primary-color-100);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabResultsItem": "sk9LD",
	"tabResultsItemActive": "hAvMi",
	"tabResultsSubtitle": "Wrs1v",
	"selectedTextColor": "nstbj"
};
export default ___CSS_LOADER_EXPORT___;
