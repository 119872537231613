// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x03dO{width:50%}.vYH4b{width:100%}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/adsAndAssets/resultsOverTime/ResultsOverTime.module.scss"],"names":[],"mappings":"AAAA,OACE,SAAA,CAGF,OACE,UAAA","sourcesContent":[".chartCard {\n  width: 50%;\n}\n\n.chartWrapper {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartCard": "x03dO",
	"chartWrapper": "vYH4b"
};
export default ___CSS_LOADER_EXPORT___;
