import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { MicroAppCustomProps } from '@wpp-open/core'
import { OsProvider } from '@wpp-open/react'
// import { StrictMode } from 'react'

import { App } from 'app/App'
import { ApiProvider } from 'providers/ApiProvider'
import 'i18n/i18n'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const Root = (props: MicroAppCustomProps) => {
  return (
    // <StrictMode>
    <QueryClientProvider client={queryClient}>
      <OsProvider {...props}>
        <ApiProvider>
          <App />
        </ApiProvider>
      </OsProvider>
    </QueryClientProvider>
    // </StrictMode>
  )
}
