// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ubnw8{height:calc(100vh - var(--wpp-os-header-height) - 2*var(--occasions-app-vertical-padding))}.DGjkS{padding-bottom:20px}.pncCU{padding-bottom:8px}.pncCU::part(item-text){color:var(--wpp-grey-color-800)}.UZdw9::part(button){background-color:#ffd02f}.UZdw9::part(text){color:var(--wpp-grey-color-1000)}.s8Pmx{padding-top:12px;padding-bottom:4px}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/AugmentedOccasionsResult.module.scss"],"names":[],"mappings":"AAAA,OACE,0FAAA,CAGF,OACE,mBAAA,CAGF,OACE,kBAAA,CAEA,wBACE,+BAAA,CAKF,qBACE,wBAAA,CAGF,mBACE,gCAAA,CAIJ,OACE,gBAAA,CACA,kBAAA","sourcesContent":[".loadingSpinnerContainer {\n  height: calc(100vh - var(--wpp-os-header-height) - 2 * var(--occasions-app-vertical-padding));\n}\n\n.resultsHeader {\n  padding-bottom: 20px;\n}\n\n.breadcrumbs {\n  padding-bottom: 8px;\n\n  &::part(item-text) {\n    color: var(--wpp-grey-color-800);\n  }\n}\n\n.miroButton {\n  &::part(button) {\n    background-color: #ffd02f;\n  }\n\n  &::part(text) {\n    color: var(--wpp-grey-color-1000);\n  }\n}\n\n.prompt {\n  padding-top: 12px;\n  padding-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSpinnerContainer": "ubnw8",
	"resultsHeader": "DGjkS",
	"breadcrumbs": "pncCU",
	"miroButton": "UZdw9",
	"prompt": "s8Pmx"
};
export default ___CSS_LOADER_EXPORT___;
