import { augmentedOccasionsApi } from 'api'
import { ChartScoreDto } from 'types/dto/chartsReportDto'

export interface OverallCognitiveImpactProps {
  tenantId: string
  channelId: string
  zoneId: string
}

export const fetchOverallCognitiveImpact = ({ tenantId, channelId, zoneId }: OverallCognitiveImpactProps) => {
  return augmentedOccasionsApi.get<ChartScoreDto>('/report/cognitive-impact', {
    params: { tenantId, channelId, zoneId },
  })
}
