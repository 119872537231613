import { useOs } from '@wpp-open/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import styles from 'app/App.module.scss'
import { AugmentedOccasionsList } from 'components/augmentedOccasionsList/AugmentedOccasionsList'
import { AugmentedOccasionsResult } from 'components/augmentedOccasionsResult/AugmentedOccasionsResult'

export function App() {
  const { osContext } = useOs()

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <div className={styles.container}>
        <div className={styles.pageContent}>
          <Routes>
            <Route index element={<AugmentedOccasionsList />} />
            <Route path="/:id" element={<AugmentedOccasionsResult />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  )
}
