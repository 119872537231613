// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sFh56{margin-top:3px;margin-bottom:43px}.M1yR7{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.HTL8W{color:var(--wpp-grey-color-600)}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsList/AugmentedOccasionsList.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,kBAAA,CAGF,OACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CAGF,OACE,+BAAA","sourcesContent":[".header {\n  margin-top: 3px;\n  margin-bottom: 43px;\n}\n\n.loadingSpinnerContainer {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.emptyListText {\n  color: var(--wpp-grey-color-600);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "sFh56",
	"loadingSpinnerContainer": "M1yR7",
	"emptyListText": "HTL8W"
};
export default ___CSS_LOADER_EXPORT___;
