// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JjK6k{--occasions-app-vertical-padding: 20px;--occasions-app-horizontal-padding: 38px;min-height:calc(100vh - var(--wpp-os-header-height));background-color:var(--wpp-grey-color-100)}.Qcpco{max-width:var(--wpp-os-content-max-width);margin:0 auto;padding:var(--occasions-app-vertical-padding) var(--occasions-app-horizontal-padding)}", "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,OACE,sCAAA,CACA,wCAAA,CAEA,oDAAA,CACA,0CAAA,CAGF,OACE,yCAAA,CACA,aAAA,CACA,qFAAA","sourcesContent":[".container {\n  --occasions-app-vertical-padding: 20px;\n  --occasions-app-horizontal-padding: 38px;\n\n  min-height: calc(100vh - var(--wpp-os-header-height));\n  background-color: var(--wpp-grey-color-100);\n}\n\n.pageContent {\n  max-width: var(--wpp-os-content-max-width);\n  margin: 0 auto;\n  padding: var(--occasions-app-vertical-padding) var(--occasions-app-horizontal-padding);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "JjK6k",
	"pageContent": "Qcpco"
};
export default ___CSS_LOADER_EXPORT___;
