import { WppTypography, WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useTranslation } from 'react-i18next'

import { useFetchInspirations } from 'api/augmentedOccasions/queries/useFetchInspirations'
import { InspirationListCardItem } from 'components/augmentedOccasionsResult/inspirations/inspirationListCardItem/InspirationListCardItem'
import styles from 'components/augmentedOccasionsResult/inspirations/Inspirations.module.scss'
import { InspirationDocumentDto } from 'types/dto/augmentedOccasionsDto'

interface Props {
  inspirationsQuery: string
}

export const Inspirations = ({ inspirationsQuery }: Props) => {
  const { osContext } = useOs()
  const { t } = useTranslation()

  const { id: tenantId } = osContext.tenant

  const { data: inspirations, isLoading: isLoadingInspirations } = useFetchInspirations({
    params: {
      query: inspirationsQuery,
      tenantId,
      documentsLimit: 10,
    },
  })

  if (isLoadingInspirations) {
    return (
      <div className={styles.inspirationsSection}>
        <WppTypography type="2xl-heading" tag="h2" className={styles.inspirationsTitle}>
          {t('inspirations_section_title')}
        </WppTypography>
        <div className={styles.inspirationsList}>
          {[...Array(10)].map((e, i) => (
            <WppSkeleton key={i} variant="rectangle" width="100%" height="284" />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.inspirationsSection}>
      <WppTypography type="2xl-heading" tag="h2" className={styles.inspirationsTitle}>
        {t('inspirations_section_title')}
      </WppTypography>
      <div className={styles.inspirationsList}>
        {inspirations.map((document: InspirationDocumentDto, index: number) => (
          <InspirationListCardItem item={document} key={index} />
        ))}
      </div>
    </div>
  )
}
