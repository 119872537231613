import {
  WppSideModal,
  WppTypography,
  WppButton,
  WppInput,
  WppSelect,
  WppListItem,
  WppTextareaInput,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import Lottie from 'lottie-react'
import { useState, useCallback, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import loadingAI from 'assets/animations/loadingAI.json'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/createOccasionSideModal/CreateOccasionSideModal.module.scss'
import { useAugmentedOccasions } from 'hooks/useAugmentedOccasions'
import { CountryCode } from 'types/dto/augmentedOccasionsDto'
import { validationSchemaAdapter } from 'utils/formValidation'

interface CountryOption {
  name: string
  code: CountryCode
}

const countryOptions: CountryOption[] = [
  { name: 'Germany', code: 'DE' },
  { name: 'United States of America', code: 'US' },
  { name: 'Great Britain', code: 'GB' },
  { name: 'China', code: 'CN' },
]
// const brandOptions = ['', 'Coca-Cola'] // TODO add brands

interface Props {
  isOpen: boolean
  onCloseModal: () => void
}

export const CreateOccasionSideModal = ({ isOpen, onCloseModal }: Props) => {
  const { t } = useTranslation()
  const { createPrompt, createPromptIsLoading } = useAugmentedOccasions()

  const [form, setForm] = useState({
    name: t('create_occassion_form.name_input.default_value'),
    country: countryOptions[0].code,
    brand: '',
    brief: t('create_occassion_form.brief_input.default_value'),
  })
  const [formErrors, setFormErrors] = useState({
    name: { status: false, message: '' },
    country: { status: false, message: '' },
    brand: { status: false, message: '' },
    brief: { status: false, message: '' },
  })

  const AssistantSettingsSchema = z.object({
    name: z.string().refine(
      value => {
        return value.length > 0
      },
      { message: t('create_occassion_form.name_input.errors.required') },
    ),
    country: z.string().refine(
      value => {
        return value.length > 0
      },
      { message: t('create_occassion_form.country_input.errors.required') },
    ),
    brief: z.string().refine(
      value => {
        return value.length > 0
      },
      { message: t('create_occassion_form.brief_input.errors.required') },
    ),
  })
  const validationSchema = validationSchemaAdapter(AssistantSettingsSchema)

  const handleInputChange = (value: string, key: string) => {
    setForm(prevState => ({
      ...prevState,
      [key]: value,
    }))
    setFormErrors(prevState => ({
      ...prevState,
      [key]: { status: false, message: '' },
    }))
  }

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      await validationSchema
        .validate(form)
        .then(() => {
          try {
            createPrompt(form)
          } catch (e) {
            console.error(e)
          }
        })
        .catch(e => {
          for (const issue of e.inner) {
            setFormErrors(prevState => ({
              ...prevState,
              [issue.path]: { status: true, message: issue.message },
            }))
          }
        })
    },
    [form, validationSchema, createPrompt],
  )

  return (
    <WppSideModal open={isOpen} onWppSideModalClose={onCloseModal} size="m">
      <h3 slot="header">{t('create_augmented_occassion')}</h3>
      <div slot="body" className={styles.sideModalBody}>
        {createPromptIsLoading ? (
          <Flex direction="column" justify="center" align="center" className={styles.promptLoadingContainer}>
            <Lottie animationData={loadingAI} />
            <div className={styles.promptLoadingTextContainer}>
              <WppTypography type="l-strong" className={styles.promptLoadingContainerTitle}>
                {t('prompt_loading.title')}
              </WppTypography>
              <WppTypography type="s-body">{t('prompt_loading.description')}</WppTypography>
            </div>
          </Flex>
        ) : (
          <form id="createOccasionForm" className={styles.form} onSubmit={handleSubmit}>
            <WppInput
              name="name"
              labelConfig={{ text: t('create_occassion_form.name_input.label') }}
              value={form.name}
              onWppChange={e => handleInputChange(e.target.value, 'name')}
              message={formErrors.name.message}
              messageType={formErrors.name.status ? 'error' : undefined}
              required
              className={styles.input}
            />
            <Flex gap={28}>
              <WppSelect
                name="country"
                labelConfig={{ text: t('create_occassion_form.country_input.label') }}
                value={form.country}
                required
                onWppChange={e => handleInputChange(e.target.value, 'country')}
                message={formErrors.country.message}
                messageType={formErrors.country.status ? 'error' : undefined}
                className={clsx(styles.input, styles.selectInput)}
              >
                {countryOptions.map((country, index) => (
                  <WppListItem value={country.code} key={index}>
                    <p slot="label">{country.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              {/* <WppSelect
                name="brand"
                labelConfig={{ text: t('create_occassion_form.brand_input.label') }}
                value={form.brand}
                onWppChange={e => handleInputChange(e.target.value, 'brand')}
                message={formErrors.brand.message}
                messageType={formErrors.brand.status ? 'error' : undefined}
                className={clsx(styles.input, styles.selectInput)}
              >
                {brandOptions.map((brand, index) => (
                  <WppListItem value={brand} key={index}>
                    <p slot="label">{brand}</p>
                  </WppListItem>
                ))}
              </WppSelect> */}
            </Flex>
            <WppTextareaInput
              labelConfig={{ text: t('create_occassion_form.brief_input.label') }}
              rows={5}
              value={form.brief}
              onWppChange={e => handleInputChange(e.target.value, 'brief')}
              required
              message={formErrors.brief.message}
              messageType={formErrors.brief.status ? 'error' : undefined}
            />
          </form>
        )}
      </div>
      <div slot="actions">
        <Flex justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={onCloseModal}>
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" form="createOccasionForm" loading={createPromptIsLoading}>
            {t('common.create')}
          </WppButton>
        </Flex>
      </div>
    </WppSideModal>
  )
}
