// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".N5uaN{padding:20px 0 22px}.z5_0I{padding-bottom:22px}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/adsAndAssets/AdsAndAssets.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CAGF,OACE,mBAAA","sourcesContent":[".adsAndAssetsSection {\n  padding: 20px 0 22px;\n}\n\n.adsAndAssetsTitle {\n  padding-bottom: 22px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adsAndAssetsSection": "N5uaN",
	"adsAndAssetsTitle": "z5_0I"
};
export default ___CSS_LOADER_EXPORT___;
