import { WppTypography, WppIconImageOff } from '@platform-ui-kit/components-library-react'
import { useState, useRef, useCallback } from 'react'

import styles from 'components/augmentedOccasionsResult/inspirations/inspirationListCardItem/InspirationListCardItem.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { InspirationDocumentDto } from 'types/dto/augmentedOccasionsDto'

interface Props {
  item: InspirationDocumentDto
}

export const InspirationListCardItem = ({ item }: Props) => {
  const thumbnailRef = useRef<HTMLImageElement>(null)

  const [thumbnailErrorCount, setThumbnailErrorCount] = useState(0)
  const thumbnailSrc = `${item.previewPath}${item.token}`

  const onThumbnailError = useCallback(() => {
    if (thumbnailErrorCount > 1) return
    setThumbnailErrorCount(thumbnailErrorCount + 1)
    thumbnailRef.current!.setAttribute('src', `${item.thumbnailPath}${item.token}`)
  }, [item, thumbnailErrorCount])

  return (
    <a href={`${item.filePath}${item.token}`} target="_blank" rel="noreferrer" className={styles.documentCard}>
      {thumbnailErrorCount > 1 ? (
        <Flex justify="center" align="center" className={styles.noThumbnailContainer}>
          <WppIconImageOff width={58} />
        </Flex>
      ) : (
        <img ref={thumbnailRef} alt="" src={thumbnailSrc} onError={onThumbnailError} />
      )}
      <Flex direction="column" justify="between" className={styles.cardContent}>
        <div>
          <div>
            {/* TODO API is not returning this */}
            <WppTypography tag="p" type="2xs-strong">
              Case studies
            </WppTypography>
          </div>
          <div>
            <WppTypography tag="p" type="m-strong" className={styles.documentTitle}>
              {item.title}
            </WppTypography>
          </div>
        </div>
        <Flex gap={8} className={styles.tagsWrapper} wrap="wrap">
          {item.tags.slice(0, 5).map((tag: string, index: number) => (
            <span className={styles.tag} key={index}>
              <WppTypography tag="span" type="s-midi" className={styles.tagText}>
                {tag}
              </WppTypography>
            </span>
          ))}
        </Flex>
      </Flex>
    </a>
  )
}
