import { SegmentedControlChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppSegmentedControlCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppActionButton,
  WppSideModal,
  WppButton,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppCheckbox,
} from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/augmentedOccasionsResult/adsAndAssets/impactScoreWithTouchpoints/touchpointsFilterModal/TouchpointsFilterModal.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { Touchpoint } from 'types/dto/chartsReportDto'

interface TouchpointData {
  channel: string
  id: string
  zones: { zone: string; id: string }[]
}

const touchpoints: TouchpointData[] = [
  {
    channel: 'Retail / in-store',
    id: 'f99c775d-7ead-4de0-a533-4b29b96581bf',
    zones: [
      { zone: 'Proximity', id: '9100f3cc-e894-4b31-ad11-80cc7e655244' },
      { zone: 'Transition', id: '5cd61791-6acb-4d53-972f-315739f04679' },
      { zone: 'Impulse', id: 'd0f5783b-4304-4182-bd10-4eba5ae4df40' },
      { zone: 'Destination', id: '274edf2b-c174-4485-8c18-7a34f0383673' },
    ],
  },
  {
    channel: 'E-Commerce',
    id: 'ec40e5da-92d4-4382-8702-6f6b4f33ffc6',
    zones: [
      { zone: 'Discovery', id: 'd901a29b-c718-4253-b72b-64f2fb65932a' },
      { zone: 'Evaluation', id: '08197047-f31f-4d5f-9348-b460c298f5ca' },
      { zone: 'Conversion', id: 'a688e79b-c073-47de-a7f9-5551730cf306' },
    ],
  },
  {
    channel: 'Social Commerce',
    id: 'ce4a906f-9f0d-4efc-b3db-ee875e04aa18',
    zones: [
      { zone: 'Consideration', id: 'eb81f882-0942-4c9a-a3a7-3b0c020ef1ec' },
      { zone: 'Awareness', id: '44750235-e01c-449b-84a1-0dcabea24a1b' },
      { zone: 'Validation', id: '54af809a-8385-4a21-8f35-becf7b29a565' },
    ],
  },
]

interface Props {
  onSelectTouchpoints: (selectedTouchpoints: Touchpoint[]) => void
}

export const TouchpointsFilterModal = ({ onSelectTouchpoints }: Props) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [selectedChannelId, setSelectedChannelId] = useState(touchpoints[0].id)
  const [selectedZoneIds, setSelectedZoneIds] = useState<string[]>([])
  const [selectedTouchpoints, setSelectedTouchpoints] = useState<Touchpoint[]>([])

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    onSelectTouchpoints(selectedTouchpoints)
  }

  const handleSelectChannel = (event: WppSegmentedControlCustomEvent<SegmentedControlChangeEventDetail>) => {
    const channelId = event.detail.value
    setSelectedChannelId(channelId as string)
  }

  const handleToggleTouchpoint = (checked: boolean, touchpoint: Touchpoint) => {
    if (checked) {
      setSelectedZoneIds([...selectedZoneIds, touchpoint.zoneId])
      const updatedSelectedTouchpoints = [...selectedTouchpoints, touchpoint]
      setSelectedTouchpoints(updatedSelectedTouchpoints)
    } else {
      setSelectedZoneIds(selectedZoneIds.filter(zoneId => zoneId !== touchpoint.zoneId))
      const updatedSelectedTouchpoints = selectedTouchpoints.filter(
        selectedTouchpoint => selectedTouchpoint.zoneId !== touchpoint.zoneId,
      )
      setSelectedTouchpoints(updatedSelectedTouchpoints)
    }
  }

  return (
    <>
      <WppActionButton onClick={handleOpenModal}>{t('ads_and_assets.view_adjust_touchpoints')}</WppActionButton>
      <WppSideModal open={isOpen} onWppSideModalClose={handleCloseModal} size="m">
        <h3 slot="header">{t('ads_and_assets.touchpoints_filter_modal_titel')}</h3>
        <div slot="body">
          <WppSegmentedControl
            value={selectedChannelId}
            size="s"
            onWppChange={handleSelectChannel}
            className={styles.channelTabs}
          >
            <>
              {touchpoints.map(touchpoint => (
                <WppSegmentedControlItem value={touchpoint.id} key={touchpoint.id}>
                  {touchpoint.channel}
                </WppSegmentedControlItem>
              ))}
            </>
          </WppSegmentedControl>
          {touchpoints
            .find(channel => channel.id === selectedChannelId)
            ?.zones.map(zone => (
              <div className={styles.checkboxWrapper} key={zone.id}>
                <WppCheckbox
                  labelConfig={{ text: zone.zone }}
                  required
                  checked={selectedZoneIds.includes(zone.id)}
                  onWppChange={({ detail: { checked } }) =>
                    handleToggleTouchpoint(checked, { channelId: selectedChannelId, zoneId: zone.id })
                  }
                />
              </div>
            ))}
        </div>
        <div slot="actions">
          <Flex justify="end">
            <WppButton variant="primary" size="m" type="submit" form="createOccasionForm" onClick={handleCloseModal}>
              {t('common.select')}
            </WppButton>
          </Flex>
        </div>
      </WppSideModal>
    </>
  )
}
