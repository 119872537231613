// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yqCP8{padding-bottom:20px}.lVx3E{padding:20px 0}.pWqp4::part(card){padding:24px}.U_4D8{padding-bottom:24px}.SDNKY{padding-bottom:8px}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/briefSection/BriefSection.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CAGF,OACE,cAAA,CAIA,mBACE,YAAA,CAIJ,OACE,mBAAA,CAGF,OACE,kBAAA","sourcesContent":[".briefSection {\n  padding-bottom: 20px;\n}\n\n.briefTitle {\n  padding: 20px 0;\n}\n\n.briefCard {\n  &::part(card) {\n    padding: 24px;\n  }\n}\n\n.briefCardSection {\n  padding-bottom: 24px;\n}\n\n.briefCardSectionTitle {\n  padding-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"briefSection": "yqCP8",
	"briefTitle": "lVx3E",
	"briefCard": "pWqp4",
	"briefCardSection": "U_4D8",
	"briefCardSectionTitle": "SDNKY"
};
export default ___CSS_LOADER_EXPORT___;
