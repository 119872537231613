// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oaRG4{padding-top:12px}.lHuk4{margin-bottom:24px}.dtOtC{width:50%}.sa3WL{height:100%;word-break:normal}.no9jN{min-height:100%;margin-top:-80px;text-align:center}.dfSMl{max-width:354px;margin-top:-80px}.zDxqb{max-width:282px;margin-bottom:19px}", "",{"version":3,"sources":["webpack://./src/components/createOccasionSideModal/CreateOccasionSideModal.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CAGF,OACE,kBAAA,CAGF,OACE,SAAA,CAGF,OACE,WAAA,CACA,iBAAA,CAGF,OACE,eAAA,CACA,gBAAA,CACA,iBAAA,CAGF,OACE,eAAA,CACA,gBAAA,CAGF,OACE,eAAA,CACA,kBAAA","sourcesContent":[".form {\n  padding-top: 12px;\n}\n\n.input {\n  margin-bottom: 24px;\n}\n\n.selectInput {\n  width: 50%;\n}\n\n.sideModalBody {\n  height: 100%;\n  word-break: normal;\n}\n\n.promptLoadingContainer {\n  min-height: 100%;\n  margin-top: -80px;\n  text-align: center;\n}\n\n.promptLoadingTextContainer {\n  max-width: 354px;\n  margin-top: -80px;\n}\n\n.promptLoadingContainerTitle {\n  max-width: 282px;\n  margin-bottom: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "oaRG4",
	"input": "lHuk4",
	"selectInput": "dtOtC",
	"sideModalBody": "sa3WL",
	"promptLoadingContainer": "no9jN",
	"promptLoadingTextContainer": "dfSMl",
	"promptLoadingContainerTitle": "zDxqb"
};
export default ___CSS_LOADER_EXPORT___;
