// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtyQM{width:100%;margin-top:20px}.TBdPK{margin:8px 0 12px}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/adsAndAssets/impactScoreWithTouchpoints/ImpactScoreWithTouchpoints.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,eAAA,CAGF,OACE,iBAAA","sourcesContent":[".card {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.toggle {\n  margin: 8px 0 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "CtyQM",
	"toggle": "TBdPK"
};
export default ___CSS_LOADER_EXPORT___;
