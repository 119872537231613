import { augmentedOccasionsApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { CreateScoreImpactReportBody, ScoreImpactReportResponseDto } from 'types/dto/chartsReportDto'

export interface Props {
  tenantId: string
  body: CreateScoreImpactReportBody
}

export const createScoreImpactReport = ({
  tenantId,
  body,
}: Props): CancelableRequestProducer<ScoreImpactReportResponseDto> =>
  augmentedOccasionsApi.post(`/report/score-impact?tenantId=${tenantId}`, body)
