// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gpmjI{padding-bottom:20px}.eCWFP{padding-bottom:12px}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/adsAndAssets/impactScoreWithTouchpoints/touchpointsFilterModal/TouchpointsFilterModal.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CAGF,OACE,mBAAA","sourcesContent":[".channelTabs {\n  padding-bottom: 20px;\n}\n\n.checkboxWrapper {\n  padding-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"channelTabs": "gpmjI",
	"checkboxWrapper": "eCWFP"
};
export default ___CSS_LOADER_EXPORT___;
