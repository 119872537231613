import { augmentedOccasionsApi } from 'api'
import { CreativesDto } from 'types/dto/creativesDto'

export interface CreativesProps {
  channelId: string
  zoneId: string
  tenantId: string
}

export const fetchCreatives = ({ channelId, zoneId, tenantId }: CreativesProps) =>
  augmentedOccasionsApi.get<CreativesDto[]>('/cco/creatives', {
    params: {
      channelId,
      zoneId,
      tenantId,
    },
  })
