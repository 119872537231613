export const countryVisualisationsMock = {
  DE: [
    //Germany
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/berlin_1.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/berlin_2.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/berlin_3.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/berlin_4.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/berlin_5.html',
  ],
  US: [
    //United States of America
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/chicago_1.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/chicago_2.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/chicago_3.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/chicago_4.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/chicago_5.html',
  ],
  GB: [
    // United Kingdom of Great Britain and Northern Ireland
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/london_1.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/london_2.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/london_3.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/london_4.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/london_5.html',
  ],
  CN: [
    //China
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/shanghai_1.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/shanghai_2.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/shanghai_3.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/shanghai_4.html',
    'https://connectedplatformfeaugoccdev-augoccfiles33e2c331-1juibczehql84.s3.amazonaws.com/staticFiles/shanghai_5.html',
  ],
}
