// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ANE1C{width:100%}.qhkaa{width:100%;margin-top:8px;display:grid;grid-gap:24px;grid-template-columns:repeat(6, 1fr)}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/creatives/Creatives.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CAGF,OACE,UAAA,CACA,cAAA,CACA,YAAA,CACA,aAAA,CACA,oCAAA","sourcesContent":[".card {\n  width: 100%;\n}\n\n.creativesListContainer {\n  width: 100%;\n  margin-top: 8px;\n  display: grid;\n  grid-gap: 24px;\n  grid-template-columns: repeat(6, 1fr);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "ANE1C",
	"creativesListContainer": "qhkaa"
};
export default ___CSS_LOADER_EXPORT___;
