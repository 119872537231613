// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E9b0F{padding:20px 0 15px}.A2MAW{padding:16px 0 20px}._AKd8{flex-grow:1}.RatyO{width:50%;height:592px}", "",{"version":3,"sources":["webpack://./src/components/augmentedOccasionsResult/occasionsResult/OccasionsResult.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CAGF,OACE,mBAAA,CAGF,OACE,WAAA,CAGF,OACE,SAAA,CACA,YAAA","sourcesContent":[".title {\n  padding: 20px 0 15px;\n}\n\n.tabResultsWrapper {\n  padding: 16px 0 20px;\n}\n\n.tabResults {\n  flex-grow: 1;\n}\n\n.occasionVisualisation {\n  width: 50%;\n  height: 592px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "E9b0F",
	"tabResultsWrapper": "A2MAW",
	"tabResults": "_AKd8",
	"occasionVisualisation": "RatyO"
};
export default ___CSS_LOADER_EXPORT___;
