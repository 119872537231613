import { useOs } from '@wpp-open/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useCreatePrompt } from 'api/augmentedOccasions/mutations/useCreatePrompt'
import { useFetchSavedOccasions } from 'api/augmentedOccasions/queries/useFetchSavedOccasions'

interface CreatePromptArgs {
  brief: string
  name: string
  country: string
  brand: string
}

export const useAugmentedOccasions = () => {
  const { osContext } = useOs()
  const { id: userId } = osContext.userDetails
  const tenantId = osContext.tenant.id
  const navigate = useNavigate()

  const { mutateAsync: createPromptMutation } = useCreatePrompt()

  const [createPromptIsLoading, setCreatePromptIsLoading] = useState(false)

  const {
    data: savedOccasions,
    isLoading: savedOccasionsIsLoading,
    refetch: refetchSavedOccasions,
  } = useFetchSavedOccasions({
    params: { itemsPerPage: 1000, userId },
  })

  const createPrompt = async ({ brief, name, country, brand }: CreatePromptArgs) => {
    try {
      setCreatePromptIsLoading(true)
      const response = await createPromptMutation({
        tenantId,
        userId,
        brief,
        brand,
        name,
        country,
        saved: true,
      })
      await refetchSavedOccasions()
      navigate(`/${response.data.id}?without-header`)
    } catch (e) {
      console.error(e)
    } finally {
      setCreatePromptIsLoading(false)
    }
  }

  return {
    createPrompt,
    createPromptIsLoading,
    savedOccasions,
    savedOccasionsIsLoading,
  }
}
